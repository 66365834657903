@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic,800italic,600italic,600&subset=latin,latin-ext);

@import     "bootstrap/bootstrap";
@import     "font-awesome/font-awesome";

@images:    "../images";


@body-bg:                   #fff;

@color-primary:             #4DBA00;
@color-gray:                #ccc;
@color-white:               #fff;
@color-black:               #000;

@link-color:                @color-black;
@link-hover-color:          @color-primary;
@link-hover-decoration:     none;

@link-footer-color:         @color-black;
@link-footer-hover-color:   @color-primary;

@font-family-base:          'Open Sans', sans-serif;

@font-size-base:            14px;
@font-size-large:           18px;
@font-size-small:           12px;
@font-size-23:              23px;



html,
body {
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
}

body {
    font-weight: 300;
    font-family: @font-family-base;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

a {
    font-size: @font-size-base;
    color: @link-color;
    text-underline: @link-hover-decoration;
    transition:         all 0.2s ease-in-out;
    -moz-transition:    all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition:      all 0.2s ease-in-out;

    &:hover,
    &:focus {
        color: @link-hover-color;
    }
}

p {
    line-height: 1.7;
    color: #000;
    margin: 0;
    font-weight: 500;
    font-size: 15px;
}


.m-b-z {
    margin-bottom: 0px !important;
}

.m-b-xl {
    margin-bottom: 20px;
}

.m-b-xxl {
    margin-bottom: 30px;
}

.m-b-xxxl {
    margin-bottom: 60px;
}

.m-t-z {
    margin-top: 0px !important;
}

.m-t-z-63 {
    margin-top: -63px !important;
}

.m-t-xl {
    margin-top: 20px;
}

.m-t-xxl {
    margin-top: 30px;
}

.m-t-xxxl {
    margin-top: 60px;
}

.p-b-z {
    padding-bottom: 0px !important;
}

.m-r-xl {
    margin-right: 20px;
}

.c-white {
    color: @color-white !important;
}

.c-black {
    color: @color-black !important;
}

.c-blue {
    color: @color-primary !important;
}

.c-green {
    color: @color-primary !important;
}


h2 {
    text-transform: uppercase;
    color: @color-gray;
    font-size: @font-size-23;
}

h3 {
    color: @color-black;
    font-weight: 700;
    text-transform: uppercase;
}


.title-section {
    font-family: @font-family-base;
    padding: 0;
    text-align: left;
    margin-bottom: 60px;
    position: relative;
    z-index: 110;

    h4, h3 {
        color: @color-black;
        font-weight: 700;
        text-transform: uppercase;
    }
    h3 {
        font-size: 26px;
    }
    p {
        color: #777;
    }
}


.lb {
    &:after {
        position: absolute;
        content: '';
        left: 50%;
        margin-left: -569px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 25px 25px 0 0;
        border-color: #ffffff transparent transparent transparent;

    }
}
.lt {
    &:after {
        position: absolute;
        content: '';
        width: 50px;
        height: 8px;
        background: #b9ff87;
        bottom: -15px;
        left: 50%;
        margin-left: -570px;
        transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
    }
    &.v:after {
        margin-left: -25px !important;
    }
}
.rt {
    &:after {
        position: absolute;
        content: '';
        width: 50px;
        height: 8px;
        background: #b9ff87;
        bottom: -15px;
        right: 50%;
        margin-right: -570px;
        transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
    }
    &.v:after {
        margin-right: -25px !important;
    }
}



header {
    .navbar-default {
        background-color: transparent;
        border: none;
        border-radius: 0;
        //height: 75px;
        min-height: 155px;
        margin-bottom: 0px;

        &.home {
            z-index: 999;
        }

        .navbar-brand {
            margin-top: 13px;

            img {
                width: 110px;
            }
        }

        .z13 a:after {
            position: absolute;
            top: -70px;
            left: 0;
            width: 100%;
            height: 70px;
            //background: rgb(243, 243, 243);
            background: @color-primary;
            content: '';
            opacity: 0;
            -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
            -moz-transition: opacity 0.3s, -moz-transform 0.3s;
            transition: opacity 0.3s, transform 0.3s;
            -webkit-transform: translateY(-50px);
            -moz-transform: translateY(-50px);
            transform: translateY(-50px);
        }

        .z13 a:hover::after,
        .z13 a:focus::after {
            opacity: 1;
            -webkit-transform: translateY(0px);
            -moz-transform: translateY(0px);
            transform: translateY(0px);
        }

        .navbar-nav > li > a {
            color: @color-black;
            font-size: 14px;
            font-family: @font-family-base;
            font-weight: 600;
            text-transform: uppercase;
            transition:         all 0.2s ease-in-out;
            -moz-transition:    all 0.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;
            -o-transition:      all 0.2s ease-in-out;

            &:hover,
            &:focus {
                color: @color-primary !important;
            }

        }

        .active1 {
            a {
                color: @color-primary !important;
            }

            a::after {
                position: absolute;
                top: -70px;
                left: 0;
                width: 100%;
                height: 70px;
                background: @color-primary;
                content: '';
                opacity: 1;
                -webkit-transform: translateY(-50px);
                -moz-transform: translateY(-50px);
                transform: translateY(-50px);
            }

        }

        .navbar-nav > .active > a,
        .navbar-nav > .active > a:hover,
        .navbar-nav > .active > a:focus {
            color: @color-primary !important;
        }

        .navbar-collapse {
            padding-right: 0px !important;
            padding-left: 0px !important;
            margin-top: 59px;
        }

    }
}


.slider_primary {
    position: relative;
    height: 100%;
    margin-top: -273px;
    z-index: 9;
}
.slider1 {
    position: relative;
    height: 550px;
    margin-top: -68px;
}
.slider2 {
    position: relative;
    height: 650px;
}


#container {
    overflow: hidden;
    background-color: @color-white;
}

#content {
    .section-content {
        position: relative;
        padding: 0 0 70px 0;

        &.map {
            padding-top: 0;
        }

        &.buttons {
            padding: 0;

            br {
                display: none;
            }
        }


        .content {
            background: @color-primary;
            z-index: 170;
            position: relative;
        }

        ul {
            color: #fff;
            line-height: 1.7;
            list-style-type: square;
            font-weight: 700;
        }

    }
}

footer {
    .footer-section {
        padding: 40px 0;
        color: #000;
        //font-size: 85%;
        background: #f9f9f9;

        img {
            position: absolute;
            top: 23px;
            right: 0;
            width: 30px;
        }
    }
}

.google-map {
    position: relative;

    #map-canvas {
        width: 100%!important;
        height: 350px;
    }
}





.koszonto, .cegtortenet, .menedzsment, .career, .gazdasag, .holding1  {
    background: @color-primary;
    z-index: 21;

    p, strong {
        color: lighten(@color-primary, 40%);
    }
}

.cegtortenet, .menedzsment, .career, .gazdasag, .holding1 {
    margin-top: 100px;
}

.holding {
    background: #f5f5f5;
}


.holding2 {
    .business {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background-position: 0 0;
        background-size: cover;
        overflow: hidden;

        .weqw {
            position: absolute;
            width: 100%;
            height: 50px;
            bottom: -10px;
            //background: #e0e0e0;
            background: #7ac4ff;
            transform: skewY(-3.3deg);
            z-index: 9;
        }

        .weqw2 {
            position: absolute;
            width: 100%;
            height: 20px;
            top: -10px;
            background: #e0e0e0;
            transform: skewY(-3.3deg);
            z-index: 9;
        }

        .title-bottom {
            position: absolute;
            left: 10px;
            bottom: 10px;
            color: @color-white;
            text-transform: uppercase;
            font-size: @font-size-small;
            z-index: 9;
            transition-duration: 0.3s;
            -webkit-transition-duration: 0.3s all ease-in-out;
            -moz-transition-duration: 0.3s all ease-in-out;
            -ms-transition-duration: 0.3s all ease-in-out;
            -o-transition-duration: 0.3s all ease-in-out;
        }

        img {
            z-index: 7;
            transition-duration: 0.3s;
            -webkit-transition-duration: 0.3s all ease-in-out;
            -moz-transition-duration: 0.3s all ease-in-out;
            -ms-transition-duration: 0.3s all ease-in-out;
            -o-transition-duration: 0.3s all ease-in-out;
        }

        &:hover {
            background-position: 0 263px;

            .title-bottom {
                color: @color-primary;
            }

            img {
                -moz-transform: scale(1.2);
                -webkit-transform: scale(1.2);
                transform: scale(1.2);
            }

        }
    }

}


.chart-text p {
    color: @color-black;
    font-size: 36px;
    line-height: 30px;
    padding-top: 127px;
}
.chart-text p strong{
    color: @color-white;
}


#mt1_menu {
    width: 1050px;
    height: 586px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    z-index: 999;
}
#mt1_menu p {
    position: absolute;
    top: 79px;
    left: 49px;
    color: #484848;
    font-size: 36px;
    line-height: 40px;
}
#mt1_menu p strong{
    color: @color-primary;
}
ul#menu {
    width: 1050px;
    height: 586px;
    display: block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
ul#menu li {
    position: absolute;
    display: block;
    background-repeat: no-repeat;
}
ul#menu li a {
    display: block;
    height: 210px;
    width: 210px;
    text-align: center;
    padding-top: 78px;
    background-repeat: no-repeat;
    font-size: 16px;
    font-weight: 700;
    color: #eefafe;
    line-height: 33px;
}


ul#menu li.it1 {
    left: 0px;
    top: 260px;
    height: 210px;
    width: 420px;
    background-color: lighten(@color-primary, 15%);
    background-image: url('@{images}/bg/bg1.png');
    background-position: left;
}
ul#menu li.it1 a {
    float: right;
}
ul#menu li.it2 {
    left: 420px;
    top: 260px;
    height: 420px;
    width: 210px;
    background-color: lighten(@color-primary, 45%);
    background-image: url('@{images}/bg/bg2.png');
    background-position: bottom;
}
ul#menu li.it3 {
    left: 630px;
    top: 50px;
    height: 420px;
    width: 210px;
    background-color: @color-primary;
    background-image: url('@{images}/bg/bg3.png');
    background-position: bottom;
}
ul#menu li.it4 {
    left: 840px;
    top: 50px;
    height: 420px;
    width: 210px;
    background-color: lighten(@color-primary, 30%);
    background-image: url('@{images}/bg/bg4.png');
    background-position: top;
}
ul#menu li.it4 a {
    margin-top: 195px;
}
ul#menu li.it5 {
    left: 420px;
    top: 190px;
    height: 70px;
    width: 210px;
    background-color: lighten(@color-primary, 30%);
}
ul#menu li.it5 a {
    display: block;
    height: 70px;
    width: 210px;
    text-align: center;
    padding-top: 25px;
    background-repeat: no-repeat;
    font-size: 16px;
    font-weight: 700;
    color: @color-white;
    line-height: normal;
}
ul#menu li.it6 {
    left: 210px;
    top: 470px;
    height: 70px;
    width: 210px;
    background-color: lighten(@color-primary, 30%);
}
ul#menu li.it6 a {
    display: block;
    height: 70px;
    width: 210px;
    text-align: center;
    padding-top: 25px;
    background-repeat: no-repeat;
    font-size: 16px;
    font-weight: 700;
    color: @color-white;
    line-height: normal;
}


.skewed-bg {
  background: #00B285;
  padding: 200px 0;
  -o-transform: skew(0deg, -10deg);
  -moz-transform: skew(0deg, -10deg);
  -ms-transform: skew(0deg, -10deg);
  -webkit-transform: skew(0deg, -10deg);
  transform: skew(0deg, -10deg);
  margin-top: -200px;
}

.skew {
    position: relative;
}

.skew_appended {
    width: 100%;
    position: absolute;
    left: 0;
    overflow: hidden;
    -webkit-transform: skewY(-1.3deg);
    -ms-transform: skewY(-1.3deg);
    transform: skewY(-1.3deg);
    -webkit-backface-visibility: hidden !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 100;
}

.skew_prepended {
    width: 100%;
    position: absolute;
    left: 0;
    overflow: hidden;
    -webkit-transform: skewY(3.3deg);
    -ms-transform: skewY(3.3deg);
    -moz-transform: skewY(3.3deg);
    -o-transform: skewY(3.3deg);
    transform: skewY(3.3deg);
    -webkit-backface-visibility: hidden !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 100;
}

.skew-bg-g {
  background: @color-primary;
}

.skew-bg-b {
  background: @color-primary;
}

.skew-bg-w {
  background: @color-white;
}

.skew_custom1 {
    position: absolute;
    background: #b9ff87;
    width: 700px;
    height: 700px;
    top: -227px;
    right: 0;
    margin-right: -350px;
    overflow: hidden;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-backface-visibility: hidden !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 9;
}

.skew_custom2 {
    position: absolute;
    background: #b9ff87;
    width: 500px;
    height: 500px;
    bottom: 32px;
    margin-left: -250px;
    left: 50%;
    overflow: hidden;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-backface-visibility: hidden !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 9;
}


.buttons {
    text-align: right;

    [class*="col-"] {
        z-index: 319;
    }
}


.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 10px 16px;
}

.btn-lg {
  font-size: 16px;
  line-height: 1.33;
  border-radius: 6px;
}

.btn-20 {
    font-size: 20px !important;
    font-weight: 700 !important;
}

.btn-primary {
  color: #fff;
  background-color: #7ac4ff;
  border-color: #7ac4ff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: @color-primary;
  border-color: @color-primary;
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.btn-primary:active:focus,
.btn-primary.active:focus {
    background-color: transparent;
    border-color: @color-primary;
}

/***********************
  OUTLINE BUTTONS
************************/

.btn.outline {
    background: none;
    padding: 6px 12px;
}
.btn-primary.outline {
    border: 2px solid #b9ff87;
    color: #4dba00;
}
.btn-primary.outline:hover, .btn-primary.outline:focus, .btn-primary.outline:active, .btn-primary.outline.active, .open > .dropdown-toggle.btn-primary {
    color: @color-primary;
    border-color: @color-primary;
}
.btn-primary.outline:active, .btn-primary.outline.active {
    border-color: #4dba00;
    color: #4dba00;
    box-shadow: none;
}

/***********************
  CUSTON BTN VALUES
************************/

.btn {
    padding: 14px 24px;
    border: 0 none;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.btn:focus, .btn:active:focus, .btn.active:focus {
    outline: 0 none;
}


.btn-xs {
    padding: 1px 5px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    border-radius: 3px !important;
}


.navbar-right {
    margin-right: 0px !important;
}


.career-bg {
    min-height: 400px;
    background: url('@{images}/career-bottom.jpg');
    background-size: cover;
}


.z {
    z-index: 999;
}

.career {
    ul {
        list-style: none!important;
        padding-left: 0!important;

        li {
            padding: 2px 0;

            a {
                color: @color-white!important;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}


.flot-chart {
    display: block;
    height: 380px;
    position: relative;
}
.flot-chart-content {
    width: 100%;
    height: 100%;
}


.holding {
    .link:hover {
        color: @color-black!important;
        text-decoration: underline;
    }
}


@media (max-width: 768px) {
    header {
        .navbar {
            margin-bottom: 0px;
        }
        .navbar-default {
            .navbar-brand {
                img {
                    width: 180px;
                }
            }

            .navbar-collapse {
                margin-top: 0px;
                border-color: #f5f5f5;

                .navbar-nav {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .navbar-nav > li > a {
                text-align: center;
            }

            a {
                &.outline {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-width: 0px;
                    border-radius: 0px;
                }
            }

            .navbar-right {
                border-top: 1px solid #f5f5f5;
            }

            .z13 a:after {
                display: none;
            }
        }
    }

    .lb:after {
        left: 0;
        margin-left: 15px;
    }

    .section-content {
        &.buttons {
            text-align: center;
            padding-bottom: 70px !important;

            .m-r-xl {
                margin-right: 0px;
                margin-bottom: 15px;
            }

            br {
                display: block !important;
            }
        }

        .holding2 .business .title-bottom {
            font-size: 10px;
        }
    }

    .chart-text p {
        padding-top: 0px;
        font-size: 26px;
        line-height: 23px;
    }

    footer {
        img {
            display: none;
        }
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .lb:after {
        margin-left: -470px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .lb:after {
        margin-left: -359px;
    }

    .section-content {
        &.buttons {
            text-align: center;
            padding-bottom: 70px !important;

            .m-r-xl {
                margin-right: 0px;
                margin-bottom: 15px;
            }

            br {
                display: block !important;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    header {
        .navbar-default {
            .navbar-collapse {
                margin-top: 0px;
                border-color: #f5f5f5;

                .navbar-nav {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            .navbar-nav > li > a {
                text-align: center;
            }

            a {
                &.outline {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-width: 0px;
                    border-radius: 0px;
                }
            }

            .navbar-right {
                border-top: 1px solid #f5f5f5;
            }

            .z13 a:after {
                display: none;
            }
        }
    }
    .navbar-toggle {
        margin-top:29px !important;
    }
}

.navbar-toggle {
    position: relative;
    float: right;
    margin-right: 15px;
    padding: 9px 10px;
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: transparent;
    background-image: none;
    border: 2px solid #f5f5f5 !important;
    border-radius: 6px;

    &:hover,
    &:focus {
        background-color: #f5f5f5 !important;
    }
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: @color-primary !important;
}



@media (min-width: 920px) and (max-width: 1090px) {
    #mt1_menu {
        width: 990px;
    }
    ul#menu {
        width: 990px;
    }

    ul#menu li a {
        height: 198px;
        width: 198px;
        padding-top: 65px;
    }

    ul#menu li.it1 {
        left: 0px;
        top: 248px;
        height: 198px;
        width: 396px;
        background-size: 198px 198px;
    }
    ul#menu li.it2 {
        left: 396px;
        top: 248px;
        height: 396px;
        width: 198px;
        background-size: 198px 198px;
    }
    ul#menu li.it3 {
        left: 594px;
        top: 50px;
        height: 396px;
        width: 198px;
        background-size: 198px 198px;
    }
    ul#menu li.it4 {
        left: 792px;
        top: 50px;
        height: 396px;
        width: 198px;
        background-size: 198px 198px;
    }

    ul#menu li.it5 {
        left: 396px;
        top: 190px;
        height: 58px;
        width: 198px;
        background-size: 198px 198px;
    }
    ul#menu li.it5 a {
        height: 58px;
        width: 198px;
        padding-top: 19px;
    }
    ul#menu li.it6 {
        left: 198px;
        top: 446px;
        height: 58px;
        width: 198px;
        background-size: 198px 198px;
    }
    ul#menu li.it6 a {
        height: 58px;
        width: 198px;
        padding-top: 19px;
    }
}

@media (min-width: 660px) and (max-width: 920px) {
    #mt1_menu {
        width: 652px;

        p {
            display: none;
        }
    }
    ul#menu {
        width: 652px;
    }

    ul#menu li a {
        height: 163px;
        width: 163px;
        padding-top: 32px;
    }

    ul#menu li.it1 {
        left: 0px;
        top: 50px;
        height: 163px;
        width: 326px;
        background-size: 163px;
    }
    ul#menu li.it2 {
        left: 326px;
        top: 213px;
        height: 163px;
        width: 326px;
        background-size: 163px;
        background-position: right;
    }
    ul#menu li.it2 a {
        padding-top: 46px;
    }
    ul#menu li.it3 {
        left: 326px;
        top: 50px;
        height: 163px;
        width: 326px;
        padding-left: 163px;
        background-size: 163px;
        background-position: left;
    }
    ul#menu li.it4 {
        left: 0px;
        top: 213px;
        height: 163px;
        width: 326px;
        background-size: 163px;
        background-position: right;
    }
    ul#menu li.it4 a {
        margin-top: 0px;
    }
    ul#menu li.it5 {
        left: 163px;
        top: 376px;
        height: 58px;
        width: 163px;
        background-size: 163px;
    }
    ul#menu li.it5 a {
        height: 58px;
        width: 163px;
        padding-top: 19px;
    }
    ul#menu li.it6 {
        left: 489px;
        top: 376px;
        height: 58px;
        width: 163px;
        background-size: 163px;
    }
    ul#menu li.it6 a {
        height: 58px;
        width: 163px;
        padding-top: 19px;
    }
}

@media (max-width: 660px) {
    #mt1_menu {
        width: 326px;
        height: 857px;

        p {
            display: none;
        }
    }
    ul#menu {
        width: 326px;
        height: 857px;
    }

    ul#menu li a {
        height: 163px;
        width: 163px;
        padding-top: 32px;
    }

    ul#menu li.it1 {
        left: 0px;
        top: 50px;
        height: 163px;
        width: 326px;
        background-size: 163px;
    }
    ul#menu li.it2 {
        left: 0px;
        top: 376px;
        height: 163px;
        width: 326px;
        background-size: 163px;
        background-position: left;
    }
    ul#menu li.it2 a {
        padding-top: 46px;
        float: right;
    }
    ul#menu li.it3 {
        left: 0px;
        top: 539px;
        height: 163px;
        width: 326px;
        background-size: 163px;
        background-position: right;
    }
    ul#menu li.it3 a {
        float: left;
    }
    ul#menu li.it4 {
        left: 0px;
        top: 213px;
        height: 163px;
        width: 326px;
        background-size: 163px;
        background-position: right;
    }
    ul#menu li.it4 a {
        margin-top: 0px;
    }
    ul#menu li.it5 {
        left: 0px;
        top: 702px;
        height: 58px;
        width: 163px;
        background-size: 163px;
    }
    ul#menu li.it5 a {
        height: 58px;
        width: 163px;
        padding-top: 19px;
    }
    ul#menu li.it6 {
        left: 163px;
        top: 702px;
        height: 58px;
        width: 163px;
        background-size: 163px;
    }
    ul#menu li.it6 a {
        height: 58px;
        width: 163px;
        padding-top: 19px;
    }
}




.gallery,
.galleries-img {
    .gallery-primary-img,
    .galleries-image {
        background-size: cover;
        height: 262px;
        background-position: center center;
        margin-bottom: 15px;

        &:hover {

        }
    }
    h4 {
        font-size: @font-size-23;
        color: @color-primary;
    }
    small {
        color: @color-black;
    }
    p {
        color: @color-black;
    }

}

.kivitelezes {
    .menu {
        h4 {
            font-size: 24px;
            font-weight: 700;
            text-align: center;
            margin-top: 30px !important;
        }
        p {
            color: #b9ff87;
            text-align: center;
        }
        center {
            color: #368200 !important;
        }
    }
}

.grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.section-content {
    &.rolunk,
    &.gallery,
    &.galleries,
    &.kapcsolat,
    &.ajanlatkeres,
    &.kp,
    &.ip {
        border-top: 1px solid #e4e4e4;
        padding-top: 35px !important;
    }
}

.page-image {
    position: absolute;
    width: 45%;
    background-size: cover;
    height: 500px;
    background-position: center center;

    &.left {
        left: 0;
    }
    &.right {
        right: 0;
    }
}

.minh-500 {
    min-height: 500px;
}
.minh-600 {
    min-height: 600px;
}
.m-b-100 {
    margin-bottom: 100px;
}

.custom {
    h3 {
        color: @color-primary;
        text-transform: none !important;
        margin-bottom: 40px;
        font-size: @font-size-large;
        font-weight: 600;
    }

    &.light {
        p {
            color: #b9ff87 !important;
        }
        h3 {
            color: #b9ff87 !important;
        }
    }
}






/* carousel */
#quote-carousel
{
  padding: 0 10px 30px 10px;
  margin-top: 30px;
}

/* Control buttons  */
#quote-carousel .carousel-control
{
  background: none;
  color: #222;
  font-size: 2.3em;
  text-shadow: none;
  margin-top: 30px;
}
/* Previous button  */
#quote-carousel .carousel-control.left
{
  left: -12px;
}
/* Next button  */
#quote-carousel .carousel-control.right
{
  right: -12px !important;
}
/* Changes the position of the indicators */
#quote-carousel .carousel-indicators
{
  right: 50%;
  top: auto;
  bottom: 0px;
  margin-right: -19px;
}
/* Changes the color of the indicators */
#quote-carousel .carousel-indicators li
{
    background: #b9ff87;
    width: 16px;
    height: 16px;
    margin: 0;
    border-radius: 50%;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 2px #b9ff87;
}
#quote-carousel .carousel-indicators .active
{
    background: @color-primary;
    box-shadow: none;

}
#quote-carousel img
{
  width: 250px;
  height: 100px
}
/* End carousel */

.item blockquote {
    border-left: none;
    margin: 0;
}

.item blockquote img {
    margin-bottom: 10px;
}

.item blockquote p:before {
    content: "\f10d";
    font-family: 'Fontawesome';
    float: left;
    margin-right: 10px;
}



/**
  MEDIA QUERIES
*/

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    #quote-carousel
    {
      margin-bottom: 0;
      padding: 0 40px 30px 40px;
    }

}

/* Small devices (tablets, up to 768px) */
@media (max-width: 768px) {

    /* Make the indicators larger for easier clicking with fingers/thumb on mobile */

    #quote-carousel .carousel-indicators {
        bottom: -20px !important;
    }
    #quote-carousel .carousel-indicators li {
        display: inline-block;
        margin: 0px 5px;
        width: 15px;
        height: 15px;
    }
    #quote-carousel .carousel-indicators li.active {
        margin: 0px 5px;
        width: 20px;
        height: 20px;
    }
}




.form-control {
    height: 44px;
    padding: 11px 12px;
    border: 1px solid #dadada;
    box-shadow: none;

    &:hover,
    &:focus {
        border-color: @color-primary;
        box-shadow: none;
    }
}




.tp-caption.NotGeneric-Title, .NotGeneric-Title {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0 0 0 0;
    border-style: none;
    border-width: 0;
    color: #b9ff87;
    font-family: @font-family-base;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    padding: 10px 0;
    text-decoration: none;
    text-transform: uppercase;
}

.tp-caption.Travel-BigCaption, .Travel-BigCaption {
    color: rgba(255,255,255,1.00);
    font-size: 50px;
    line-height: 50px;
    font-weight: 700;
    font-style: normal;
    font-family: @font-family-base;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
    border-style: none;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
}
.tp-caption.Travel-SmallCaption, .Travel-SmallCaption {
    color: rgba(255,255,255,1.00);
    font-size: 25px;
    line-height: 30px;
    font-weight: 300;
    font-style: normal;
    font-family: @font-family-base;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
    border-style: none;
    border-width: 0px;
    border-radius: 0px 0px 0px 0px;
}













#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9990;
}
#loader {

    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 500px;
    height: 500px;
    background: url('@{images}/bgb-500.png') no-repeat center center;
    margin: -250px 0 0 -250px;
    padding-top: 288px;
    z-index: 9992;

}

    #loader-wrapper .loader-section {
        position: fixed;
        top: 0;
        width: 51%;
        height: 100%;
        background: #fff;
        z-index: 9988;
        -webkit-transform: translateX(0);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(0);  /* IE 9 */
        transform: translateX(0);  /* Firefox 16+, IE 10+, Opera */
    }

    #loader-wrapper .loader-section.section-left {
        left: 0;
    }

    #loader-wrapper .loader-section.section-right {
        right: 0;
    }

    /* Loaded */
    .loaded #loader-wrapper .loader-section.section-left {
        -webkit-transform: translateX(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: translateX(-100%);  /* IE 9 */
                transform: translateX(-100%);  /* Firefox 16+, IE 10+, Opera */

        -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
                transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }

    .loaded #loader-wrapper .loader-section.section-right {
        -webkit-transform: translateX(100%);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: translateX(100%);  /* IE 9 */
                transform: translateX(100%);  /* Firefox 16+, IE 10+, Opera */

-webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
        transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }

    .loaded #loader {
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
    }
    .loaded #loader-wrapper {
        visibility: hidden;

        -webkit-transform: translateY(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
            -ms-transform: translateY(-100%);  /* IE 9 */
                transform: translateY(-100%);  /* Firefox 16+, IE 10+, Opera */

        -webkit-transition: all 0.3s 1s ease-out;
                transition: all 0.3s 1s ease-out;
    }

    /* JavaScript Turned Off */
    .no-js #loader-wrapper {
        display: none;
    }
    .no-js h1 {
        color: #222222;
    }

